import * as Sentry from '@sentry/browser';

export default (config) => {
  let parsedConfig;

  try {
    parsedConfig = JSON.parse(config);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return;
  }

  const { client, user, emulating } = parsedConfig;

  // Extend our configuration
  client.ignoreErrors = [
    'ResizeObserver loop limit exceeded', // See https://github.com/WICG/resize-observer/issues/38
  ];

  client.denyUrls = [
    /pagead2\.googlesyndication\.com/i, // Ignore issues talking to Google tracking
    /\.smartlook\.cloud/i, // Don't worry if Smartlook isn't online
  ];

  client.beforeSend = (event, hint) => {
    if (hint.originalException === 'Timeout') return null; // See https://github.com/getsentry/sentry-javascript/issues/2514
    return event;
  };

  client.integrations = [
    // Captures errors on failed requests from Fetch and XHR and attaches request
    // and response information.
    Sentry.httpClientIntegration(),
  ];

  // This option is required for capturing headers and cookies (HttpClient)
  client.sendDefaultPii = true;

  // Inject Sentry client
  Sentry.init(client);

  if (user) {
    Sentry.setUser(user);
  }

  if (emulating) {
    Sentry.setContext('Emulating', emulating);
  }
};

window.Sentry = Sentry;
